<template>
  <div class="form-page login-page d-flex">
    <div class="form-page__form pe-15">
      <alert-component
        :alertType="alertType"
        :showAlert="showAlert"
        :alertText="alertText"
      ></alert-component>
      <FormHeaderComponent
        :formHeaderBtn="{ show: false }"
        formHeaderText="تسجيل الدخول"
        formHeaderDescription="ادخل البيانـات الخاصـة بـك لتقـوم بالدخول لحسابـك"
      />
      <v-form class="rounded mt-14" ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="col-12 py-0 px-2 mb-n1">
            <v-subheader class="black--text px-0">
              البريد الإلكتروني
            </v-subheader>
            <v-text-field
              :rules="[rules.required, rules.email]"
              v-model="model.email"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="col-12 py-0 px-2">
            <v-subheader class="black--text px-0">كلمة المرور </v-subheader>
            <v-text-field
              :rules="[rules.required]"
              v-model="model.password"
              dense
              outlined
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 py-0 px-2">
            <div class="d-flex align-items-center justify-between">
              <v-checkbox
                class="mt-0 pt-0"
                value="1"
                label="تذكر حسابي"
                type="checkbox"
                :v-model="model.remember"
                color="error"
              ></v-checkbox>
              <v-btn
                @click="$router.push({ name: 'forget-password' })"
                text
                color="black"
                class="form-page__form__forget-password pa-0"
              >
                نسيت كلمة المرور
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="mt-15 pa-0 flex-wrap">
        <v-btn
          @click="login()"
          dark
          large
          color="error"
          class="w-100 rounded-lg"
        >
          <span class="font-bold">تسجيل الدخول</span>
        </v-btn>
        <div class="align-items-center justify-center d-flex w-100 gap-1 mt-5">
          <span class="gray--text text-sm font-bold"> لا تملك حساب؟ </span>
          <v-btn
            @click="$router.push({ name: 'register' })"
            text
            color="black"
            class="form-page__form__new-account h-unset pa-0"
          >
            <span class="text-sm font-bold">تسجيل حساب جديد </span>
          </v-btn>
        </div>
      </v-card-actions>
    </div>
    <FormDescriptionComponent />
    <loader-component :showLoader="showLoader"></loader-component>
  </div>
</template>

<script>
import FormHeaderComponent from "@/common/components/FormHeaderComponent.vue";
import FormDescriptionComponent from "@/common/components/FormDescriptionComponent.vue";
import authentication from "@/common/services/authentication";
import LoaderComponent from "@/common/components/LoaderComponent.vue";
import AlertComponent from "@/common/components/AlertComponent.vue";

export default {
  name: "form-pageView",
  props: {},
  mixins: [authentication],
  components: {
    LoaderComponent,
    FormHeaderComponent,
    FormDescriptionComponent,
    AlertComponent,
  },
  data() {
    return {
      alertType: "",
      alertText: "",
      showAlert: false,
      showPassword: false,
      showLoader: false,
      model: {
        email: "",
        password: "",
        remember: false,
      },
      valid: true,
      rules: {
        required: (value) =>
          !!value || [true, false].includes(value) || "هذا الحقل إلزامي",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "البريد الالكتروني  غير صحيح",
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async login() {
      this.showAlert = false;
      await this.validate();
      if (this.valid) {
        try {
          this.showLoader = true;
          const response = await this.signin(this.model);
          if (response.isSuccess) {
            const userInfo = {
              id: response.data.id,
              fullName: response.data.fullUserName,
              email: response.data.userEmail,
              mobile: response.data.mobile,
              userType: response.data.userType,
              userActions: response.data.userActions,
              isActive: response.data.isActive,
            };
            const token = response.data.token;

            // Dispatch actions to save userInfo and token in Vuex store
            this.$store.dispatch("login", { userInfo, token });

            // Redirect to the shipping requests page
            this.$router.push({ name: "shipping-requests" });
          } else {
            this.alertType = "error";
            this.alertText = response.error.message;
            this.showAlert = true;
          }
          this.showLoader = false;
        } catch (error) {
          console.error(error);
          this.showAlert = true;
          this.alertType = "error";
          this.alertText = "حدث خطأ أثناء تسجيل الدخول";
          this.showLoader = false;
        }
      }
    },
  },
};
</script>