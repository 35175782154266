// Define the directive
const visibility = {
    // Called when the bound element is inserted into the DOM
    inserted(el, binding) {
        // Initial visibility state based on the value of the directive argument
        if (!binding.value) {
            el.style.visibility = 'hidden';
        }
    },
    // Called when the bound element's attribute or directive value changes
    update(el, binding) {
        // Toggle visibility based on the updated value of the directive argument
        if (binding.value) {
            el.style.visibility = null; // Restore default display (CSS)
        } else {
            el.style.visibility = 'hidden';
        }
    }
};

export default visibility;