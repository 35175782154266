import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        themes: {
            light: {
                primary: '#2A3548',
                error: '#C53C1C',
                secondary: '#44BFAF',
                black:"#000000",
                gray:"#777777",
                lightgray:"#909090",
                success:"#40A578"
            },
        },
    },
});
