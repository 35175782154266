<template>
  <div class="form-header">
    <v-btn
      v-if="formHeaderBtn.show"
      @click="formHeaderBackBtn"
      class="mb-6"
      outlined
      rounded
      color="#DADADA"
      height="38"
    >
      <span class="black--text d-flex align-center justify-center gap-8">
        <img src="@/assets/images/chevron-right.svg" alt="Chevron Right" />
        {{ formHeaderBtn.text }}
      </span>
    </v-btn>
    <p class="form-header__text mb-2">{{ formHeaderText }}</p>
    <p class="form-header__description mb-0">
      {{ formHeaderDescription }}
    </p>
  </div>
</template>


<script>
export default {
  props: {
    formHeaderText: {
      type: String,
      required: true,
    },
    formHeaderDescription: {
      type: String,
      required: true,
    },
    formHeaderBtn: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formHeaderBackBtn() {
      this.$emit("formHeaderBackBtn");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-header {
  &__text {
    font-size: 25px;
    font-weight: 700;
    color: $black;
  }
  &__description {
    font-size: 15px;
    font-weight: 400;
    color: #9a9a9a;
  }
}
</style>