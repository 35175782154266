export default {
  data() {
    return {
    }
  },
  methods: {
    async signin(data) {
      const path = `/User/login`;
      const options = {
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}${path}`,
        data,
      };
      const response = await this.http(options);
      return response.data;
    },
    async createUser(data) {
      const path = this.isServiceProvider ? "/User/CreateServiceUser" : "/User/CreateUser";
      const options = {
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}${path}`,
        data,
      };
      const response = await this.http(options);
      return response.data;
    },
    async otpResend(data) {
      const path = `/User/ResendOtp`;
      const options = {
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}${path}`,
        data,
      };
      const response = await this.http(options);
      return response.data;
    },
    async ConfirmationOtp(data) {
      const path = this.$route.query["otp_type"]==="login" ? "/User/ForgotPasswordVerification" : "/User/ConfirmationOtp";
      const options = {
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}${path}`,
        data,
      };
      const response = await this.http(options);
      return response.data;
    },
    async forgotPassword(data) {
      const path = `/User/ForgotPassword`;
      const options = {
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}${path}`,
        data,
      };
      const response = await this.http(options);
      return response.data;
    },
    async resetPassword(data) {
      const path = `/User/ResetPassword`;
      const options = {
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}${path}`,
        data,
      };
      const response = await this.http(options);
      return response.data;
    },
  },
};
