import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        userInfo: null,
        token: null,
    },
    mutations: {
        setLoginStatus(state, status) {
            state.isLoggedIn = status;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setToken(state, token) {
            state.token = token;
        }
    },
    actions: {
        updateLoginStatus({ commit }, status) {
            commit('setLoginStatus', status);
        },
        setUserInfo({ commit }, userInfo) {
            commit('setUserInfo', userInfo);
        },
        setToken({ commit }, token) {
            commit('setToken', token);
        },
        login({ commit }, { userInfo, token }) {
            commit('setLoginStatus', true);
            commit('setUserInfo', userInfo);
            commit('setToken', token);
        },
        logout({ commit }) {
            commit('setLoginStatus', false);
            commit('setUserInfo', null);
            commit('setToken', null);
        }
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        userInfo: state => state.userInfo,
        token: state => state.token,
    },
    plugins: [createPersistedState()],
});
