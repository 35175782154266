<template>
  <div>
    <!-- There Are Four Types For The Alert Component To Use => [success, info, warning, error] -->
    <v-alert
      class="alert mb-5"
      :class="alertClass"
      outlined
      :color="alertInfo[alertType]['color']"
      dense
      :icon="alertInfo[alertType]['icon']"
      text
      transition="scale-transition"
      :value="show"
      :height="alertHeight"
    >
      <!-- Make Alert Text Color Black If The Alert Type is Not Error -->
      <span
        :class="alertType != 'error' ? 'alert__text' : ''"
        class="text-sm"
        >{{ alertText }}</span
      >
    </v-alert>
  </div>
</template>


<script>
export default {
  props: {
    alertText: {
      type: String,
      default: "إشعار",
      required: true,
    },
    showAlert: {
      type: Boolean,
      default: false,
      required: true,
    },
    alertType: {
      type: String,
      default: "success",
      required: true,
    },
    alertClass: {
      type: String,
      default: "",
      required: false,
    },
    alertHeight: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      alertInfo: {
        success: { color: "#success", icon: "mdi-check-circle-outline" },
        info: { color: "#316FCD", icon: "mdi-alert-outline" },
        warning: { color: "#F4B25C", icon: "mdi-alert-outline" },
        error: { color: "#C53C1C", icon: "mdi-alert-circle-outline" },
      },
      show: this.showAlert,
    };
  },
  watch: {
    showAlert: {
      handler(nv) {
        this.show = nv;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  &__text {
    color: $black;
  }
}
</style>