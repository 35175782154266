<template>
  <div class="home">
    <div class="home__service mb-15">
      <div class="home__service__content mt-15">
        <h2 class="home__service__content__title">
          منصة كاشيب..
          <br />
          منصة واحدة لجميع شحناتك
        </h2>
        <p class="home__service__content__description">
          نقدم خدمات شحن وتخليص جمركي موثوقة وسريعة لكل الوجهات العالمية، مع
          ضمان الكفاءة والسرعة في التسليم
        </p>
        <div class="home__service__content__btns">
          <v-btn depressed large color="error" class="rounded-lg">
            تواصــل مع الخبيــر
          </v-btn>
          <v-btn depressed large color="primary" class="rounded-lg">
            ادخل بيانـات الشحنـة
          </v-btn>
        </div>
      </div>
      <div class="home__service__img"></div>
      <div class="home__service__types">
        <div class="home__service__types__item">
          <img
            src="@/assets/images/landing-page/online-shipping.svg"
            alt="online-shipping"
          />
          <span> شحن اونلاين</span>
        </div>
        <div class="home__service__types__item">
          <img
            src="@/assets/images/landing-page/customs-clearance.svg"
            alt="customs-clearance"
          />
          <span> التخليص الجمركي </span>
        </div>
        <div class="home__service__types__item">
          <img src="@/assets/images/landing-page/shipping.svg" alt="shipping" />
          <span> النقل البحري </span>
        </div>
        <div class="home__service__types__item">
          <img
            src="@/assets/images/landing-page/air-transport.svg"
            alt="air-transport"
          />
          <span> النقل الجوي </span>
        </div>
        <div class="home__service__types__item">
          <img
            src="@/assets/images/landing-page/road-transport.svg"
            alt="road-transport"
          />
          <span> النقل البري</span>
        </div>
        <div class="home__service__types__item">
          <img src="@/assets/images/landing-page/storage.svg" alt="storage" />
          <span> التخزين </span>
        </div>
      </div>
    </div>
    <div class="home__section my-15">
      <div class="home__section__content">
        <div>
          <h3 class="home__section__content__title">
            تتبـع الشحنــة
            <br />
            مـع كاشيـب
            <img
              class="home__section__content__title__qoutes"
              src="@/assets/images/landing-page/qoutes.svg"
              alt="qoutes"
            />
          </h3>
          <p class="home__section__content__description">
            تمكنك منصة كاشيب من تتبع كافة المراحل التي تمر <br />
            بها الشحنة في الوقت الفعلي مما يوفر لك رؤية كاملة <br />
            لمراحل الشحن و يساعدك على مراقبة الشحنة بشكل <br />
            دقيق من لحظة الإرسال حتى الوصول
          </p>
        </div>
      </div>
      <div class="home__section__img">
        <img src="@/assets/images/landing-page/map.png" alt="Map" />
      </div>
    </div>
    <div class="home__section my-15">
      <div class="home__section__img">
        <img
          src="@/assets/images/landing-page/electronic-documents.svg"
          alt="electronic-documents"
        />
      </div>
      <div class="home__section__content">
        <div>
          <h3 class="home__section__content__title">
            الوثائق الإلكترونية
            <br />
            مـع كاشيـب
            <img
              class="home__section__content__title__qoutes"
              src="@/assets/images/landing-page/qoutes.svg"
              alt="qoutes"
            />
          </h3>
          <p class="home__section__content__description">
            نقوم برقمنة كافة وثائق الشحنة وأرشفتها على منصة <br />
            كاشيب مما يسهل انتقال البيانات والوثائق بين الأطراف <br />
            ذات الصلة بالشحنة توفر هذه العملية السلاسة والشفافية <br />
            وتعزز من كفاءة إدارة الشحنات من خلال الوصول السريع <br />
            و الآمن إلى المعلومات
          </p>
        </div>
      </div>
    </div>
    <div class="home__section my-15">
      <div class="home__section__content">
        <div>
          <h3 class="home__section__content__title">
            الذكــاء الاصطناعي
            <br />
            مـع كاشيـب
            <img
              class="home__section__content__title__qoutes"
              src="@/assets/images/landing-page/qoutes.svg"
              alt="qoutes"
            />
          </h3>
          <p class="home__section__content__description">
            تستخدم منصة كاشيب للشحن والتخليص الجمركي <br />
            تقنيات الذكاء الاصطناعي لرقمنة الوثائق وتصنيف <br />
            الشحنات بدقة وسرعة، مما يوفر الوقت والجهد ويقلل <br />
            من الأخطاء، ويعزز من كفاءة العمليات اللوجستية
          </p>
        </div>
      </div>
      <div class="home__section__img">
        <img src="@/assets/images/landing-page/ai.svg" alt="AI" />
      </div>
    </div>
    <div class="home__section my-15">
      <div class="home__section__img">
        <img
          src="@/assets/images/landing-page/enhancements.svg"
          alt="Enhancements"
        />
      </div>
      <div class="home__section__content">
        <div>
          <h3 class="home__section__content__title">
            تحسين إدارة سلسلة
            <br />
            الإمداد مع كاشيب
            <img
              class="home__section__content__title__qoutes"
              src="@/assets/images/landing-page/qoutes.svg"
              alt="qoutes"
            />
          </h3>
          <p class="home__section__content__description">
            تُعزز كاشيب إدارة سلسلة الإمداد من خلال رقمنة الوثائق باستخدام
            <br />
            الذكاء الاصطناعي، مما يقلل من الأخطاء ويوفر الوقت، وتتيح تتبع
            <br />
            الشحنات في الوقت الفعلي، مما يعزز الشفافية والكفاءة في
            <br />
            العمليات اللوجستية.
          </p>
        </div>
      </div>
    </div>
    <div class="home__section my-15">
      <div class="home__section__content">
        <div>
          <h3 class="home__section__content__title">
            خدمــات عديـدة
            <br />
            مـع كاشيـب
            <img
              class="home__section__content__title__qoutes"
              src="@/assets/images/landing-page/qoutes.svg"
              alt="qoutes"
            />
          </h3>
          <p class="home__section__content__description">
            تقدم شركات الشحن والتخليص الجمركي خدمات نقل <br />
            البضائع وتخليصها من الجمارك بسرعة وكفاءة. تشمل <br />
            هذه الخدمات تجهيز الوثائق، دفع الرسوم الجمركية <br />
            وتسليم الشحنات بأمان إلى وجهتها النهائية.
          </p>
        </div>
      </div>
      <div class="home__section__img">
        <img src="@/assets/images/landing-page/services.svg" alt="Services" />
      </div>
    </div>
    <v-row class="home__services-types my-15">
      <V-col class="home__services-types__item col-4">
        <img
          class="home__services-types__item__img"
          src="@/assets/images/landing-page/online-shipping-black.svg"
          alt="Online Shipping"
        />
        <h4 class="home__services-types__item__title">شحن اونلاين</h4>
        <p class="home__services-types__item__content">
          لوريم إيبسوم هو نص تجريبي يستخدم في صناعة <br />
          الطباعة والتنضيد. بدأ استخدامه في القرن الخامس <br />
          عشر عندما قامت مطبعة غير
        </p>
      </V-col>
      <V-col class="home__services-types__item col-4">
        <img
          class="home__services-types__item__img"
          src="@/assets/images/landing-page/customs-clearance-black.svg"
          alt="Customs Clearance"
        />
        <h4 class="home__services-types__item__title">التخليص الجمركي</h4>
        <p class="home__services-types__item__content">
          لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ
          استخدامه في القرن الخامس عشر عندما قامت مطبعة غير
        </p>
      </V-col>
      <V-col class="home__services-types__item col-4">
        <img
          class="home__services-types__item__img"
          src="@/assets/images/landing-page/shipping-black.svg"
          alt="Shipping"
        />
        <h4 class="home__services-types__item__title">النقل البحري</h4>
        <p class="home__services-types__item__content">
          لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ
          استخدامه في القرن الخامس عشر عندما قامت مطبعة غير
        </p>
      </V-col>
      <V-col class="home__services-types__item col-4">
        <img
          class="home__services-types__item__img"
          src="@/assets/images/landing-page/air-transport-black.svg"
          alt="Air Transport"
        />
        <h4 class="home__services-types__item__title">النقل الجوي</h4>
        <p class="home__services-types__item__content">
          لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ
          استخدامه في القرن الخامس عشر عندما قامت مطبعة غير
        </p>
      </V-col>
      <V-col class="home__services-types__item col-4">
        <img
          class="home__services-types__item__img"
          src="@/assets/images/landing-page/road-transport-black.svg"
          alt="Road Transport"
        />
        <h4 class="home__services-types__item__title">النقل البري</h4>
        <p class="home__services-types__item__content">
          لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ
          استخدامه في القرن الخامس عشر عندما قامت مطبعة غير
        </p>
      </V-col>
      <V-col class="home__services-types__item col-4">
        <img
          class="home__services-types__item__img"
          src="@/assets/images/landing-page/storage-black.svg"
          alt="Storage"
        />
        <h4 class="home__services-types__item__title">التخزين</h4>
        <p class="home__services-types__item__content">
          لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ
          استخدامه في القرن الخامس عشر عندما قامت مطبعة غير
        </p>
      </V-col>
    </v-row>
    <div class="home__steps my-15">
      <img
        class="w-100"
        src="@/assets/images/landing-page/home-steps.svg"
        alt="Steps"
      />
    </div>
    <div class="home__section my-15">
      <div class="home__section__content">
        <div>
          <h3 class="home__section__content__title">
            نبــذة عن منصـة
            <br />
            كاشيـب
            <img
              class="home__section__content__title__qoutes"
              src="@/assets/images/landing-page/qoutes.svg"
              alt="qoutes"
            />
          </h3>
          <p class="home__section__content__description">
            منصة كاشيب منصة رقمية مبتكره ومُصممة خصيصًا لتبسيط وتسريع وتقليل
            تكاليف شحن البضائع
            <br />
            تعتمد كاشيب على تقنية سحابية متطورة لتجميع كافة أطراف العملية
            اللوجستية تحت سقف واحد
            <br />
            موفرًة أدوات ذكية لتسهيل جميع مراحل الشحن وتقليل الوقت والجهد
            المبذولين. تتيح كاشيب
            <br />
            لعملائها شحن وتتبع وإدارة شحناتهم بكفاءة عالية، من لحظة الاستلام حتى
            الوصول إلى
            <br />
            الوجهة النهائية
          </p>
        </div>
      </div>
      <div class="home__section__img">
        <img src="@/assets/images/landing-page/brief.svg" alt="Brief" />
      </div>
    </div>
    <v-row class="home__join-us my-15">
      <v-col class="col-6 pa-0">
        <h3 class="home__questions__title mb-0">
          انضــم الى شبكتنــا
          <img
            class="home__questions__title__qoutes"
            src="@/assets/images/landing-page/qoutes.svg"
            alt="qoutes"
          />
        </h3>
      </v-col>
      <v-col class="col-6 d-flex justify-end pa-0">
        <v-btn
          @click="
            $router.push({
              name: 'register',
              query: { service_provider: 'true' },
            })
          "
          color="error"
        >
          انضــم الي كاشيــب
          <v-icon right dark> mdi-arrow-left </v-icon>
        </v-btn>
      </v-col>
      <v-col class="col-12 d-flex justify-end pa-0">
        <p class="home__join-us__text">
          إذا كنت مقدم خدمة شحن وتخليص جمركي وترغب في توسيع نطاق أعمالك وزيادة
          قاعدة عملائك، فإن الانضمام إلى شبكتنا يمكن أن يوفر لك العديد من
          الفوائد والفرص الانضمام إلى شبكة متكاملة من مقدمي خدمات الشحن والتخليص
          الجمركي يمكن أن يفتح أمامك آفاقاً جديدة ويعزز من مكانتك في السوق. إليك
          شرح مفصل عن الفوائد والفرص التي يمكن أن تحققها من خلال الانضمام إلى
          شبكتنا فى كاشيــب
        </p>
      </v-col>
      <v-col class="home__join-us__item col-3">
        <img
          class="home__join-us__item__img"
          src="@/assets/images/landing-page/customers.svg"
          alt="Icon"
        />
        <p class="home__join-us__item__title">زيادة قاعدة العملاء</p>
        <p class="home__join-us__item__description">
          لوريم إيبسوم هو نص تجريبي يستخدم في الطباعة والتنضيد. بدأ استخدامه في
          القرن عشر عندما قامت مطبعة غير
        </p>
      </v-col>
      <v-col class="home__join-us__item col-3">
        <img
          class="home__join-us__item__img"
          src="@/assets/images/landing-page/settings.svg"
          alt="Icon"
        />
        <p class="home__join-us__item__title">تحسين الكفاءة التشغيلية</p>
        <p class="home__join-us__item__description">
          لوريم إيبسوم هو نص تجريبي يستخدم في الطباعة والتنضيد. بدأ استخدامه في
          القرن عشر عندما قامت مطبعة غير
        </p>
      </v-col>
      <v-col class="home__join-us__item col-3">
        <img
          class="home__join-us__item__img"
          src="@/assets/images/landing-page/shipping-black.svg"
          alt="Icon"
        />
        <p class="home__join-us__item__title">توسيع نطاق الخدمات</p>
        <p class="home__join-us__item__description">
          لوريم إيبسوم هو نص تجريبي يستخدم في الطباعة والتنضيد. بدأ استخدامه في
          القرن عشر عندما قامت مطبعة غير
        </p>
      </v-col>
      <v-col class="home__join-us__item col-3">
        <img
          class="home__join-us__item__img"
          src="@/assets/images/landing-page/headphone.svg"
          alt="Icon"
        />
        <p class="home__join-us__item__title">الدعم الفني والتسويقي</p>
        <p class="home__join-us__item__description">
          لوريم إيبسوم هو نص تجريبي يستخدم في الطباعة والتنضيد. بدأ استخدامه في
          القرن عشر عندما قامت مطبعة غير
        </p>
      </v-col>
    </v-row>
    <div class="home__questions my-15">
      <h3 class="home__questions__title">
        الأسئلـة الشائعـة
        <br />
        مـع كاشيـب
        <img
          class="home__questions__title__qoutes"
          src="@/assets/images/landing-page/qoutes.svg"
          alt="qoutes"
        />
      </h3>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel v-for="panel in panels" :key="panel.id">
          <!-- <v-expansion-panel-header hide-actions>
            {{ panel.title }}
          </v-expansion-panel-header> -->
          <v-expansion-panel-header hide-actions>
            <template v-slot:default="{ open }">
              <div>
                {{ panel.title }}
                <v-icon>
                  <template v-if="open">mdi-minus</template>
                  <template v-else>mdi-plus</template>
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ panel.content }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    panel: [0],
    panels: [
      {
        id: 1,
        title: "ما هي الخدمات التي تقدمونها في مجال الشحن الدولي؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 2,
        title: "كيف يمكنني تتبع شحنتي؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 3,
        title: "ما هي المستندات المطلوبة لتخليص الشحنات من الجمارك؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 4,
        title: "هل تقدمون خدمات التخزين؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 5,
        title: "كيف يمكنني الحصول على عرض سعر لشحنتي؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 6,
        title: "ما هي الدول التي تقومون بالشحن إليها؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
    ],
    servicesTypes: [
      {
        id: 1,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 2,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 3,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 4,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 5,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 6,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
.home {
  &__service {
    min-height: 720px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__content {
      // height: calc(100% - 30px);
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 24px;
      &__img {
        width: 150px;
        cursor: pointer;
      }
      &__title {
        font-weight: 700;
        font-size: 55px;
        line-height: 65px;
      }
      &__description {
        color: $lightBlack;
        font-size: 14px;
        font-weight: 400;
      }
      &__btns {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    &__img {
      // height: calc(100% - 30px);
      background-image: url("@/assets/images/landing-page/content.svg");
      background-size: 100% 100%;
      width: 50%;
      img {
        height: 100%;
        width: 100%;
        transform: translateX(-36px);
      }
    }
    &__types {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-top: 40px;
      &__item {
        display: flex;
        align-items: center;
        gap: 15px;
        img {
          margin-bottom: 5px;
        }
        span {
          font-size: 14;
          font-weight: 400;
          color: #303030;
        }
      }
    }
  }
  &__section {
    padding: 6px 0px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__content {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__title {
        font-weight: 700;
        font-size: 45px;
        line-height: 50px;
        position: relative;
        margin-bottom: 24px;
        display: inline-block;
        &__qoutes {
          position: absolute;
          top: 0;
          left: -50px;
        }
      }
      &__description {
        color: $lightBlack;
        font-size: 16px;
        font-weight: 400;
      }
    }
    &__img {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin: auto;
      }
    }
  }
  &__questions {
    &__title {
      font-weight: 700;
      font-size: 45px;
      line-height: 50px;
      position: relative;
      margin-bottom: 24px;
      display: inline-block;
      &__qoutes {
        position: absolute;
        top: 0;
        left: -50px;
      }
    }
  }
  &__services-types {
    background-color: #f3f6f5;
    border-radius: 20px;
    padding: 36px 24px;
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__img {
      }
      &__title {
        font-size: 17px;
        font-weight: 700;
        color: $black;
        margin: 16px 0px;
      }
      &__content {
        color: $lightBlack;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  &__join-us {
    background-color: #f3f0ef;
    border-radius: 20px;
    padding: 48px;
    &__title {
      font-weight: 700;
      font-size: 45px;
      line-height: 50px;
      position: relative;
      margin-bottom: 24px;
      display: inline-block;
      &__qoutes {
        position: absolute;
        top: 0;
        left: -50px;
      }
    }
    &__text {
      font-size: 14px;
      font-weight: 400;
      border-radius: 20px;
      background-color: #f9f7f7;
      padding: 24px;
      margin: 32px 0px;
      line-height: 25px;
    }
    &__item {
      padding: 0;
      padding-inline-end: 32px;
      &:last-child {
        padding-inline-end: unset;
      }
      &__img {
      }
      &__title {
        font-size: 17px;
        font-weight: 700;
        margin: 16px 0px;
      }
      &__description {
        color: $lightBlack;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
        line-height: 21px;
      }
    }
  }
}
</style>